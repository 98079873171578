window.TNCMS = window.TNCMS || {};
window.TNCMS.Access = TNCMS.Access || [];
window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.bp || (__tnt.subscription.bp = []);
window.__tnt.subscription.access || (__tnt.subscription.access = []);
window.__tnt.subscription.access.output || (__tnt.subscription.access.output = {});
window.__tnt.subscription.access.promises || (window.__tnt.subscription.access.promises = []);

__tnt.subscription.access.output.subscription = function(method, pos){
    var modalEl = document.getElementById('service-promo-modal'),
        methodsEl = null,
        servicePanelClasses = "promotion-service subscription-service panel panel-default",
        refererUrl = '',
        description = "",
        image = null,
        imageLayout = "horizontal",
        imageEl = null,
        offersEl = document.getElementById('access-offers-modal'),
        productData = null,
        siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
        siteName = "";

    if(modalEl){ methodsEl = modalEl.querySelector('.access-methods-list') }
    if(offersEl){ methodsEl = offersEl.querySelector('.access-methods-list') }
    if(siteNameEl){ siteName = siteNameEl.content }

    if(method){
        productData = {
            'product_id': method.metadata.service_id,                           // subscription service id
            'sku': method.id,                                                   // offer rate id
            'name': method.name,                                                // title of the service
            'url': method.metadata.service_info.purchase_url,                   // the current url being displayed? Or is this supposed to be the offer's link url?
            'position': pos,                                                    // same as the position count in the impression
            'image_url': method.metadata.service_info.graphic.location,         // image returned by the offer
            'quantity': 1,                                                      // always 1 for subscriptions
            'coupon': "",                                                       // coupon code entered by the user
            'category': method.type,                                            // name of the offer group
            'price': 0,                                                         // price of the offer in dollar.cents
            'variant': "",                                                      // name of the rate and not the rate id?
            'brand': siteName,                                                  // the sites brand label
            'currency': "",                                                     // the currency code of the money used for this item
            'viewed_from': "Article",                                           // type of view of the product internal use
            'legacy_context': "Article"                                         // Legacy view data Article/Block/Dashboard
        };


        /* Check for image and add to description */
        if(method.metadata && method.metadata.service_info && method.metadata.service_info.graphic && method.metadata.service_info.graphic.location){
            image = method.metadata.service_info.graphic;

            if(image){
                if(image.height > image.width){ imageLayout = "vertical"; }
                if(image.location){ imageEl = '<figure class="photo layout-' + imageLayout + ' padding-bottom"><div class="image"><img class="img-responsive full default" src="' + image.location + '" alt="' + method.name + '"/></div></figure>' }
            }
            if(!offersEl){ description += imageEl }
        }
    
        if(method.metadata && method.metadata.service_info && method.metadata.service_info.description){
            description += method.metadata.service_info.description;
        }
    }

    /*
     * Elements to modify within the template element before it is added to the DOM and displayed to the user.
     */
    var elements = [
        {
            'element' : '.subscription-service.panel',
            'attr' : [
                {
                    'attr' : 'class',
                    'value' : servicePanelClasses
                },
                {
                    'attr' : 'data-method-id',
                    'value' : method.id
                }
            ]
        },{
            'element' : '.method-header',
            'html' : method.name
        },{
            'element' : '.method-description',
            'html' : description
        }
    ],
    linkElement = {
        'element' : 'a.method-link',
        'html' : "Get Started",
        'attr': [
            {
                'attr' : 'data-subscription-service',
                'value' : method.metadata.service_id
            },{
                'attr' : 'data-name',
                'value' : method.name
            },
            {
                'attr' : 'data-product',
                'value' : JSON.stringify(productData)
            }
        ]
    };
    
    if(typeof(method.metadata.service_info.purchase_url)!="undefined"){
        linkElement.attr.push({
            'attr' : 'href',
            'value' : method.metadata.service_info.purchase_url
        })
    }else{
        linkElement.attr.push({
            'attr' : 'class',
            'value' : 'method-link btn btn-primary btn-lg disabled'
        })
    }

    elements.push(linkElement);
    
    if(offersEl){
        elements.push({
            'element':'.offer-details',
            'html': description
        });
        elements.push({
            'element':'.method-image',
            'html': imageEl
        });
    }
    
    /*
     * Callback to run after modifying elements within the template
     *
     * @prop t - document fragment from the template element with values from elements inserted
     */
    callback = function(t){}

    return { elements: elements, callback: callback };
}
